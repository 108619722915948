import AsyncSuspense from '@/components/async-suspense'
import { ErrorBoundary } from '@sentry/react'
import React from 'react'
// import Home from '@/features/home'
// import { getHomePageProps } from '@/helper/home/home-seo'
const Home = React.lazy(() => import('@/features/home'))
function Page() {
  return (
    <AsyncSuspense>
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    </AsyncSuspense>
  )
}

export { Page }
